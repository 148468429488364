// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import {
    LineChart,
    BarChart,
    PieChart,
    ScatterChart,
    EffectScatterChart
} from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    LegendComponent,
    DatasetComponentOption,
    GeoComponent,
    TransformComponent,
    MarkLineComponent
} from 'echarts/components';
// 标签自动布局，全局过渡动画等特性
import {
    LabelLayout,
    UniversalTransition
} from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import {
    CanvasRenderer
} from 'echarts/renderers';

// 注册必须的组件
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    LegendComponent,
    TransformComponent,
    GeoComponent,
    LineChart,
    BarChart,
    PieChart,
    ScatterChart,
    EffectScatterChart,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer,
    MarkLineComponent
]);

// 接下来的使用就跟之前一样，初始化图表，设置配置项
/**
 * 
 * @param {* dom元素id } id 
 * @param {* 线条颜色 } color 
 * @param {* 年龄数组 } ages 
 * @param {* 真实状态数组 } data1 
 * @param {* 标准值 } data2 
 * @param {* 理论状态数组 } data3 
 */
var myCharts = []

function initBarChart(id, xAxis, series, tooltip, legend) {
    let myChart = echarts.init(document.getElementById(id));
    let options = {
        grid: {
            left: '10%',
            top: '10%',
            bottom: '10%',
            right: '10%'
        },
        legend,
        tooltip,
        xAxis,
        yAxis: {
            type: 'value',
        },
        series
    }
    myChart.setOption(options)
    myCharts.push(myChart)
}

function initsmallBarChart(id, xAxis, series, yAxis, tooltip) {
    let myChart = echarts.init(document.getElementById(id));
    let options = {
        grid: {
            left: '15%',
            top: '10%',
            bottom: '18%',
            right: '10%'
        },
        xAxis: {
            type: 'category',
            data: xAxis,
            axisLabel: {
                color: '#fff'
            },
            axisTick: {
                show: false,
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                color: '#fff',
                formatter: (yAxis ? (a) => a * 100 + "%" : '{value}')
            },
            min: (yAxis ? 0 : 'dataMin'),
            max: (yAxis ? 1 : 'dataMax'),
            splitLine: {
                lineStyle: {
                    color: '#052e5e',
                    width: 1,
                    shadowBlur: 0,
                }
            }
        },
        series
    }
    myChart.setOption(options)
    myCharts.push(myChart)
}

function initLineChart(id, xAxis, series, tooltip, legend, xlabel) {
    echarts.init(document.getElementById(id)).dispose(); // 销毁实例
    let myChart = echarts.init(document.getElementById(id));
    let options = {
        legend,
        grid: {
            left: '10%',
            top: '10%',
            bottom: '18%',
            right: '10%'
        },
        tooltip,
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxis,
            axisLabel: {
                formatter: ('{value}' + xlabel)
            }
        },
        yAxis: {
            type: 'value',
        },
        series
    }
    myChart.setOption(options, true)
    myCharts.push(myChart)
}

function initsmallLineChart(id, xAxis, series, tooltip, legend) {
    let myChart = echarts.init(document.getElementById(id));
    let options = {
        legend,
        grid: {
            left: '10%',
            top: '10%',
            bottom: '10%',
            right: '10%'
        },
        tooltip,
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xAxis,
            axisLabel: {
                color: '#fff',
            },
            axisTick: {
                show: false,
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                color: '#fff'
            },
            splitLine: {
                lineStyle: {
                    color: '#052e5e',
                    width: 1,
                    shadowBlur: 0,
                }
            }
        },
        series
    }
    myChart.setOption(options)
    myCharts.push(myChart)
}

function initPieChart(id, series, legend, tooltip) {
    let myChart = echarts.init(document.getElementById(id));

    let options = {
        grid: {
            left: '10%',
            top: '10%',
            bottom: '10%',
            right: '10%'
        },
        legend,
        tooltip,
        series
    }
    myChart.setOption(options)
    myCharts.push(myChart)
}

function pileupBarChart(id, xxmc, ywc, wwc,axisLabel,x) {
    let myChart = echarts.init(document.getElementById(id))

    let option = {
        tooltip: {},
        legend: {},
        grid: {
            left: '7%',
            top: '15%',
            bottom: x,
            right: '7%'
        },
        xAxis: {
            data: xxmc,
            axisLabel: axisLabel,
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                name: "已完成",
                type: "bar",
                stack: "check",
                data: ywc,
                itemStyle: {
                    color: "#92cb76"
                }
            },
            {
                name: "未完成",
                type: "bar",
                stack: "check",
                data: wwc,
                itemStyle: {
                    color: "#fac858"
                }
            }
        ]
    };
    myChart.setOption(option)
    myCharts.push(myChart)
}


window.onresize = function () {
    for (let i = 0; i < myCharts.length; i++) {
        myCharts[i].resize();
    }
}


export {
    initBarChart,
    initsmallBarChart,
    initLineChart,
    initsmallLineChart,
    initPieChart,
    pileupBarChart,
};