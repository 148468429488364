<template>
    <div class="main">
        <div class="nav">
            <el-row :gutter="40" type="flex" justify="space-between">
                <!-- <el-col :span="4">
                    <div class="nav_item nav_itemljyj">
                        <span class="span_top">{{commonInfo.ljnum}}元</span>
                        <span class="span_bottom">累计佣金收益</span>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div class="nav_item nav_itemdyyj">
                        <span class="span_top">{{commonInfo.dynum}}元</span>
                        <span class="span_bottom">当月佣金收益</span>
                    </div>
                </el-col> -->
                <el-col :span="4">
                    <div class="nav_item nav_itemljhz">
                        <span class="span_top">{{commonInfo.hznum}}人</span>
                        <span class="span_bottom">累计用户</span>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div class="nav_item nav_itemjrhz">
                        <span class="span_top">{{commonInfo.drnum}}人</span>
                        <span class="span_bottom">今日新增用户</span>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div class="nav_item nav_itemljapp">
                        <span class="span_top">{{commonInfo.appnum}}人</span>
                        <span class="span_bottom">累计APP用户</span>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div class="nav_item nav_itemjrapp">
                        <span class="span_top">{{commonInfo.appnum1}}人</span>
                        <span class="span_bottom">今日新增APP用户</span>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="charts">
            <el-row :gutter="10">
                <el-col :span="12">
                    <div class="chart">
                        <div class="title">
                            <span>用户增长趋势</span>
                            <el-date-picker
                                v-model="time"
                                @change = "ondateChange"
                                type="month"
                                format="yyyy-MM"
                                value-format="timestamp"
                                @focus = "onFocus"
                                @blur = "onBlur"
                                placeholder="选择月份">
                            </el-date-picker>
                        </div>
                        <div id="updataChart">
                        </div>
                    </div>
                    <div class="chart">
                        <div class="title">
                            <span>月增用户统计</span>
                            <el-date-picker
                                v-model="time1"
                                type="year"
                                @change = "ondateChange1"
                                @focus = "onFocus"
                                @blur = "onBlur"
                                value-format="timestamp"
                                placeholder="选择年份">
                            </el-date-picker>
                        </div>
                        <div id="barChart">
                        </div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="chart">
                        <div class="title">
                            <span>近视程度人数</span>
                        </div>
                        <div id="barChart1">
                        </div>
                    </div>
                    <div class="chart piechart">
                        <div class="pie">
                            <div class="title1">
                                <div>视力分布</div>
                            </div>
                            <div id="pieChart1">
                            </div>
                        </div>
                        <div class="pie">
                            <div class="title1">
                                <div>用户渠道占比</div>
                            </div>
                            <div id="pieChart2">
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import {
        initBarChart,
        initLineChart,
        initPieChart,
    } from './echart.js';
export default {
    data() {
      return {
        commonInfo: {},
        time: '',
        time1: '',
        year: '',
        month: '',
        updataChartData: {
            time: '',
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'line',
                    label: {
                        show: true,
                        formatter: '{value}日'
                    }
                },
                padding: [5, 30, 5, 10]
            },
            legend: {
                show: true,
                icon: 'roundRect',
                right: '10%'
            },
            xAxis: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
                series: [
                    {
                        name: 'APP用户数',
                        data: [11, 17, 51, 54, 58, 64, 13, 46, 24, 15, 24, 22, 32, 15, 24,
                            50, 23, 21, 24, 23, 14, 54, 23, 56, 25, 91, 82, 28, 26, 40, 21
                        ],
                        type: 'line',
                        color: '#5571c8',
                        symbol: 'none'
                    },
                    {
                        name: '患者数',
                        data: [51, 54, 58, 64, 13, 46, 24, 15, 24, 22, 32, 15, 24,
                            50, 23, 21, 24, 23, 14, 54, 23, 56, 25, 115, 82, 28, 26, 40, 11, 17, 21
                        ],
                        type: 'line',
                        color: '#91cc75',
                        symbol: 'none'
                    },
                ]
        },
        usersEyeChartData: {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                    label: {
                        show: true,
                        formatter: '{value}月'
                    }
                },
                padding: [5, 30, 5, 10]
            },
            legend: {
                show: true,
                icon: 'roundRect',
                right: '10%'
            },
            xAxis: {
                ype: 'category',
                data: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
            },
            series: [{
                name: 'APP用户数',
                data: [100, 45, 80, 98, 31, 64, 13, 46, 54, 321, 654, 21, 654, 321, 564, 66, 10, 23, 71, 43],
                    type: 'bar',
                    color: '#5470c6'
                }, {
                name: '患者数',
                data: [115, 131, 561, 542, 584, 64, 113, 546, 254, 151, 424, 222, 232, 515, 124,
                        250, 110, 19, 39, 99
                    ],
                    type: 'bar',
                    color: '#91cc75'
                }]
        },
        usersEyeChartData1: {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                    label: {
                        show: true,
                    }
                },
                padding: [5, 30, 5, 10]
            },
            legend: {
                show: true,
                icon: 'roundRect',
                right: '10%'
            },
            xAxis: {
                ype: 'category',
                data: ["远视", "正常", "轻度近视", "中度", "高度","超高度","中度近视"],
            },
            series: [{
                data: [115, 131, 561, 542, 584,50,21],
                type: 'bar',
                color: '#5470c6'
                }]
        },
        pieData1: {
            tooltip: {
                trigger: 'item',
                formatter: (params) => {
                    let str = `${params.name}占比：${params.percent}%<br/>`
                    // str += `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>
                    //                 <span style="margin-right:20px" >${params.name}</span>    ${params.value.toLocaleString()}`
                    return str
                }
            },
            legend: {
                show: false,
                top: 'bottom',
            },
            series: [
                {
                    name: 'Nightingale Chart',
                    type: 'pie',
                    radius: [10, 100],
                    center: ['50%', '40%'],
                    roseType: 'area',
                    itemStyle: {
                         borderRadius: 3
                    },
                    labelLine: {
                        normal: {
                            length: '1',
                            length2: '1',
                        }
                    },
                    data: [
                        { value: 38, name: '正视' },
                        { value: 18, name: '远视' },
                        { value: 52, name: '轻度近视'},
                        { value: 30, name: '其他' },
                    ]
                }
            ]
        },
        pieData2: {
            tooltip: {
                trigger: 'item',
                formatter: (params) => {
                    let str = `${params.name}占比：${params.percent}%<br/>`
                    // str += `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>
                    //                 <span style="margin-right:20px" >${params.name}</span>    ${params.value.toLocaleString()}`
                    return str
                }
            },
            legend: {
                show: false,
                top: 'bottom',
            },
            series: [
                {
                    name: 'Nightingale Chart',
                    type: 'pie',
                    radius: [10,100],
                    center: ['50%', '40%'],
                    roseType: 'area',
                    itemStyle: {
                         borderRadius: 3
                    },
                    labelLine: {
                        length: '2',
                        length2: '2',
                    },
                    // label: {
                    //     normal: {
                    //         position: 'inner'
                    //     }
                    // },
                    data: [
                        { value: 48, name: '本店' },
                        { value: 32, name: '一级' },
                        { value: 16, name: '二级' },
                        { value: 5, name: '其他' },
                    ]
                }
            ]
        },
      };
    },
    methods: {
        getYD() {
            var date = new Date().getTime();
            console.log("date=>",date)
            // var Y = date.getFullYear();
            // var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1);
            // this.time = date
            // this.time1 = date
            return date;
        },
        onFocus() {
            this.time1 = ""
            this.time = ""
        },
        onBlur() {
            this.time = this.month
            this.time1 = this.year
        },
        ondateChange(value) {
            if(value){
                this.month = value
                this.initLine()
            }
            console.log("日期=>",value)
        },
        ondateChange1(value) {
            if(value){
                //this.time1 = value
                this.year = value
                this.initBar()
            }
            console.log("日期=>",value)
        },
        getCommonInfo() {
            this.$api.count.getCommonInfo().then(res => {
                this.commonInfo = res.obj 
            })
        },
        initLine() {
            this.$api.count.getUsersAdd({"ym":this.month}).then(res => {
            this.updataChartData.xAxis = res.app.apptime
            this.updataChartData.series[0].data = res.app.appnum
            this.updataChartData.series[1].data = res.hz.hznum           
            initLineChart('updataChart', this.updataChartData.xAxis, this.updataChartData.series, this.updataChartData.tooltip, this.updataChartData.legend, '日');
            })
        },
        initBar() {
            this.$api.count.getMonthAdd({"ym":this.year}).then(res => {
                console.log("bar=>",res)
                this.usersEyeChartData.xAxis.data = res.app.apptime
                this.usersEyeChartData.series[0].data = res.app.appnum
                this.usersEyeChartData.series[1].data = res.hz.hznum                 
                initBarChart('barChart', this.usersEyeChartData.xAxis, this.usersEyeChartData.series, this.usersEyeChartData.tooltip, this.usersEyeChartData.legend);
            })
            
        },
        initBar1() {
            this.$api.count.getDeepCounts().then(res => {
                console.log("bar1=>",res)
                let array = res.obj
                let xa = []
                let da = []
                for (let i = 0; i < array.length; i++) {
                    xa.push(array[i].conclusion)
                    da.push(array[i].sum)
                }
                this.usersEyeChartData1.xAxis.data = xa
                this.usersEyeChartData1.series[0].data = da
                initBarChart('barChart1', this.usersEyeChartData1.xAxis, this.usersEyeChartData1.series, this.usersEyeChartData1.tooltip, this.usersEyeChartData1.legend);
            })
            
        },
        initPie1() {
            this.$api.count.getAllDeeps().then(res => {
                console.log("pie1=>",res)
                let array = res.obj.map(a => {
                        return {
                            name: a.conclusion,
                            value: a.sum
                        }
                    })
                this.pieData1.series[0].data = array.reverse();
                console.log("pie1,series=>",this.pieData1.series[0].data)
                initPieChart('pieChart1', this.pieData1.series, this.pieData1.legend, this.pieData1.tooltip);
            })
        },
        initPie2() {
            this.$api.count.getAllUsers().then(res => {
                let array = res.obj.map(a => {
                        return {
                            name: a.conclusion,
                            value: a.sum
                        }
                    })
                this.pieData2.series[0].data = array.reverse();
                console.log("pie1,series=>",this.pieData2.series[0].data) 
                initPieChart('pieChart2', this.pieData2.series, this.pieData2.legend, this.pieData2.tooltip);    
            })
        },
        initChats() {
            this.getCommonInfo()
            this.initLine()
            this.initBar()
            this.initBar1()
            this.initPie1()
            this.initPie2()
        }
    },
    created() {
        
    },
    mounted() {
        this.initChats()
    }
}
</script>

<style scoped>
    .main {
        width: 100%;
        height: 100%;
        padding: 20px 0;
        overflow: auto;
    }
    .nav {
        width: 100%;
        padding: 0 20px 0 20px;
        margin-bottom: 15px;
    }
    .nav_item {
        border-radius: 0.47vw;
	    opacity: 0.92;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 4.43vw;
    }
    .span_top {
        font-size: 1.15vw;
        color: #ffffff;
    }
    .span_bottom {
        font-size: 0.94vw;
        color: #ffffff;
    }
    .nav_itemljyj {
        background-image: linear-gradient(
		#e01e1e, 
		#e01e1e), 
        linear-gradient(90deg, 
            #46e0d6 0%, 
            #1099d1 100%);
        background-blend-mode: normal, 
            normal;
    }
    .nav_itemdyyj {
        background-image: linear-gradient(
		#ea2662, 
		#ea2662), 
        linear-gradient(90deg, 
            #7683d9 0%, 
            #d8a0fe 100%);
        background-blend-mode: normal, 
            normal;
    }
    .nav_itemljhz {
        background-image: linear-gradient(
		#3ec063, 
		#3ec063), 
	    linear-gradient(90deg, 
		#9270ff 0%, 
		#fd5d9f 100%);
        background-blend-mode: normal, 
            normal;
    }
    .nav_itemjrhz {
        background-image: linear-gradient(
		#00d9cc, 
		#00d9cc), 
	    linear-gradient(90deg, 
		#f8cf8b 0%, 
		#f35969 100%);
        background-blend-mode: normal, 
            normal;
    }
    .nav_itemljapp {
        background-image: linear-gradient(
		#7081e8, 
		#7081e8), 
	    linear-gradient(90deg, 
		#764ffd 0%, 
		#a7cdfe 100%);
        background-blend-mode: normal, 
            normal;
    }
    .nav_itemjrapp {
        background-image: linear-gradient(
		#32a8ee, 
		#32a8ee), 
	    linear-gradient(90deg, 
		#7683d9 0%, 
		#d8a0fe 100%);
        background-blend-mode: normal, 
            normal;
    }
    .charts {
        margin-top: 20px;
        padding: 0 20px 0 20px;
    }
    .chart {
        margin-bottom: 10px;
        height: 19.58vw;
        background-color: #ffffff;
        padding: 15px;
        box-shadow: 0.08vw 0.13vw 0.36vw 0vw 
		rgba(0, 0, 0, 0.3);
    }
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .title>span {
        font-family: SourceHanSansCN-Regular;
        font-size: 1.04vw;
        font-weight: normal;
        font-stretch: normal;
        color: #1e1e1e;
    }
    .title1>div {
        font-family: SourceHanSansCN-Regular;
        font-size: 1.04vw;
        font-weight: normal;
        font-stretch: normal;
        color: #1e1e1e;
        width: 100%;
        text-align: center;
    }
    /deep/.el-date-editor{
        width: 120px;
        height: 20px;
    }
    /deep/.el-input__inner{
        height: 30px;
    }
    /deep/.el-input__icon{
        line-height: 30px;
    }
    .piechart {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .pie {
        width: 100%;
        height: 100%;
        height: 19.58vw;
    }

    #updataChart,
    #barChart,
    #barChart1,
    #pieChart1,
    #pieChart2 {
        width: 100%;
        height: calc(100% - 30px);
        margin-top: 20px;
    }
</style>